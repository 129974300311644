<template>
  <CSidebar :show="showAdminSidebar" fixed @update:show="toggleAdminSidebar">
    <CSidebarBrand class="d-md-down-none">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="sidebar" />
  </CSidebar>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      items: [
        {
          _name: "CSidebarNavItem",
          name: this.$t("admin.restaurants.title"),
          to: { name: "admin.restaurants" },
          icon: "cilBasket"
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("admin.catalogue.title"),
          icon: "cilLibrary",
          route: "catalogue",
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.catalogue.cuisines.title"),
              to: { name: "admin.catalogue.cuisines" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.catalogue.filters.title"),
              to: { name: "admin.catalogue.filters" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.catalogue.taxes.title"),
              to: { name: "admin.catalogue.taxes" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.catalogue.types.title"),
              to: { name: "admin.catalogue.types" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.catalogue.packages.title"),
              to: { name: "admin.catalogue.packages" }
            }
          ]
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("admin.ordersMobile.title"),
          icon: "cilStar",
          route: "orders-mobile",
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.delivery_mobile"),
              to: { name: "admin.ordersMobile.delivery" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.pickup_mobile"),
              to: { name: "admin.ordersMobile.pickup" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.with_comments_mobile"),
              to: { name: "admin.ordersMobile.with_comments" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.cash_mobile"),
              to: { name: "admin.ordersMobile.cash" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.canceled_mobile"),
              to: { name: "admin.ordersMobile.canceled" }
            }
          ]
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("admin.orders.title"),
          icon: "cilCart",
          route: "orders",
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.delivery"),
              to: { name: "admin.orders.delivery" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.pickup"),
              to: { name: "admin.orders.pickup" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.with_comments"),
              to: { name: "admin.orders.with_comments" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.cash"),
              to: { name: "admin.orders.cash" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.canceled"),
              to: { name: "admin.orders.canceled" }
            }
          ]
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("admin.users.title"),
          to: { name: "admin.users" },
          icon: "cilGroup"
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("admin.couriers.title"),
          to: { name: "admin.couriers" },
          icon: "cilTruck"
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("admin.intervals.title"),
          icon: "cilSpreadsheet",
          route: "intervals",
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.delivery"),
              to: { name: "admin.intervals.delivery" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.pickup"),
              to: { name: "admin.intervals.pickup" }
            }
          ]
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("management.statistics.delivery"),
          icon: "cilBarChart",
          route: "statistics/delivery",
          roles: ["superadmin"],
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.restaurants.title"),
              to: { name: "admin.statistics.delivery.restaurants" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.orders.title"),
              to: { name: "admin.statistics.delivery.orders" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.tips.title"),
              to: { name: "admin.statistics.delivery.tips" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.amex.title"),
              to: { name: "admin.statistics.delivery.amex" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.package_balances.title"),
              to: { name: "admin.statistics.delivery.package_balances" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.accounting.title"),
              to: { name: "admin.statistics.delivery.accounting" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.general.title"),
              to: { name: "admin.statistics.delivery.general" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.purchases.title"),
              to: { name: "admin.statistics.delivery.purchases" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.purchased_orders.title"),
              to: { name: "admin.statistics.delivery.purchased_orders" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.delivered_orders.title"),
              to: { name: "admin.statistics.delivery.delivered_orders" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.cancelled_orders.title"),
              to: { name: "admin.statistics.delivery.cancelled_orders" }
            }
          ]
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("management.statistics.pickup"),
          icon: "cilBarChart",
          route: "statistics/pickup",
          roles: ["superadmin"],
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.restaurants.title"),
              to: { name: "admin.statistics.pickup.restaurants" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.orders.title"),
              to: { name: "admin.statistics.pickup.orders" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.tips.title"),
              to: { name: "admin.statistics.pickup.tips" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.amex.title"),
              to: { name: "admin.statistics.pickup.amex" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.package_balances.title"),
              to: { name: "admin.statistics.pickup.package_balances" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.accounting.title"),
              to: { name: "admin.statistics.pickup.accounting" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.general.title"),
              to: { name: "admin.statistics.pickup.general" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.purchases.title"),
              to: { name: "admin.statistics.pickup.purchases" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.purchased_orders.title"),
              to: { name: "admin.statistics.pickup.purchased_orders" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.delivered_orders.title"),
              to: { name: "admin.statistics.pickup.delivered_orders" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.cancelled_orders.title"),
              to: { name: "admin.statistics.pickup.cancelled_orders" }
            }
          ]
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("management.statistics.generals"),
          icon: "cilBarChart",
          route: "statistics/general",
          roles: ["superadmin"],
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.coupons.title"),
              to: { name: "admin.statistics.coupons" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.balances.title"),
              to: { name: "admin.statistics.balance" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.user_balances.title"),
              to: { name: "admin.statistics.user_balance" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t(
                "management.statistics.user_balances_on_date.title"
              ),
              to: { name: "admin.statistics.balance_on_date" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.payRestaurants.title"),
              to: { name: "admin.statistics.payrestaurants" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.global.title"),
              to: { name: "admin.statistics.global_stat" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("management.statistics.dishes_rest.title"),
              to: { name: "admin.statistics.dishes_rest" }
            }
          ]
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("admin.extra_shop.title"),
          to: { name: "admin.extra_shop" },
          icon: "cilStar"
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("admin.coupons.title"),
          to: { name: "admin.coupons" },
          icon: "cilNotes"
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("admin.package_balances.title"),
          to: { name: "admin.package_balances" },
          icon: "cilInbox"
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("admin.notifications.title"),
          to: { name: "admin.notifications" },
          icon: "cilBell",
          route: "notifications",
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.notifications.dishes.title"),
              to: { name: "admin.notifications.dishes" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.notifications.stock.title"),
              to: { name: "admin.notifications.stock" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.notifications.restaurants.title"),
              to: { name: "admin.notifications.restaurants" }
            }
          ]
        },
        {
          _name: "CSidebarNavDropdown",
          name: this.$t("admin.settings.title"),
          to: { name: "admin.settings" },
          icon: "cilCog",
          route: "settings",
          items: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.settings.general.title"),
              to: { name: "admin.settings.general" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.settings.hero.title"),
              to: { name: "admin.settings.hero" }
            },
            {
              _name: "CSidebarNavItem",
              name: this.$t("admin.settings.slider.title"),
              to: { name: "admin.settings.slider" }
            }
          ]
        }
      ]
    };
  },

  computed: {
    ...mapState(["showAdminSidebar"]),
    ...mapState("auth", ["user"]),
    filteredItems() {
      const f = item => {
        if (item.roles) {
          return this.user.roles.some(role => item.roles.includes(role.name));
        }
        if (item.items) {
          return (item.items = item.items.filter(f)).length;
        }

        return true;
      };

      return this.items.filter(f);
    },
    sidebar() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.filteredItems
        }
      ];
    }
  },

  methods: {
    ...mapActions(["toggleAdminSidebar"])
  }
};
</script>
